<script>
const propName = "value", eventName = "input"
export default {
  name: "InputNumber",
  model: {
    prop: propName,
    event: eventName
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    maxValue: {
      type: [Number, String],
      default: "",
    },
    minValue: {
      type: [Number, String],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    regex: {
      type: String,
      default: "",
    },
    [propName]: {
      validator: (v) => Array.isArray(v) || typeof v === 'string'|| typeof v === 'number' || v == null,
      required: true
    },
    fieldId: {
      type: Number,
      required: true,
    },
    defaultValue: {
      type: String,
      default: "",
    },
    savedValue: {
      required: true,
      validator: (v) => Array.isArray(v) || typeof v === 'string'|| typeof v === 'number' || v == null,
    },
    required: {
      type: Boolean,
    },
    multipleForms: {
      type: Boolean,
    },
    multipleFormsIndex: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
    },
  },
  data: function (){
    return {
      requiredRule: (v) => !!v || v === 0 || this.$t("pages.questionnaire.rules.required"),
      minRule: (v) => v >= Number(this.minValue) || this.$t("pages.questionnaire.rules.min", {
        n: this.minValue
      }),
      maxRule: (v) => v <= Number(this.maxValue) || this.$t("pages.questionnaire.rules.max", {
        n: this.maxValue
      }),
      regexRule: (v) => RegExp(this.regex).test(v) || this.$t("pages.questionnaire.rules.format")
    }
  },
  computed: {
    rules() {
      let rulesArray = []
      if (this.required) rulesArray.push(this.requiredRule)
      if (this.minValue || this.minValue === 0) rulesArray.push(this.minRule)
      if (this.maxValue) rulesArray.push(this.maxRule)
      if (this.regex) rulesArray.push(this.regexRule)
      return rulesArray
    },
    model: {
      get() {
        return this[propName]
      },
      set(payload) {
        this.$emit(eventName, {
          fieldId: this.fieldId,
          value: payload
        })
      }
    },
  },
  created() {
    let value
    if (!!this.savedValue && this.savedValue !== "None") {
      value = this.savedValue
    } else if (this.defaultValue) {
      value = this.defaultValue
    }
    if (value)
      this.model = this.multipleForms ? value[this.multipleFormsIndex] : value
  },
  methods: {
    onEnter() {
      this.$emit("enter");
    }
  }
}
</script>

<template>
  <div class="wrapper">
    <v-text-field
      v-model.number="model"
      :hint="hint"
      type="number"
      pattern="\d*"
      single-line
      :placeholder="placeholder"
      :label="label"
      :disabled="disabled"
      outlined
      :rules="rules"
      block
      x-large
      validate-on-blur
      class="field"
      @keydown.enter="onEnter"
    />
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  width: 100%;
}
.field::v-deep {
  border-radius: 10px;
  .v-btn__content {
    color: black;
  }
}
</style>